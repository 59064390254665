<template>
  <v-dialog v-model="show_modal_var" persistent max-width="700">
    <v-card>
      <!-- boton en esquina de cerrar -->
      <div class="headerIcon">
        <v-icon class="secondary--text" @click="show_modal_var = false">
          mdi-close
        </v-icon>
      </div>

      <!-- form -->
      <v-card-text>
        <v-row class="mx-sm-6">
          <v-col cols="12">
            <span
              class="text-h6 secondary--text"
              v-text="'Disminución de fondos asignados'"
            />
          </v-col>
          <v-col cols="12" class="d-flex flex-column text-center text-sm-start">
            <span> Institución </span>
            <span class="secondary--text">
              {{ info_unidad?.nombre_institucion }}
            </span>
            <span> Unidad </span>
            <span class="secondary--text">
              {{ info_unidad?.nombre }}
            </span>
            <span> Cifrado </span>
            <span class="secondary--text">
              {{ info_unidad?.cifrado_presupuestario }}
            </span>
            <span> Tipo de fondo </span>
            <span class="secondary--text">
              {{ info_unidad?.tipo_fondo == 1 ? "Proyecto" : "Votado" }}
            </span>
            <span> Monto asignado </span>
            <span class="secondary--text">
              {{
                Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(info_unidad?.monto_asignado)
              }}
            </span>
            <span> Monto disponible </span>
            <span class="secondary--text">
              {{
                Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(info_unidad?.monto_disponible)
              }}
            </span>
          </v-col>
          <v-col cols="12">
            <money-text-field-component
              ref="REF_MONEY_CONST"
              :error-messages="montoSolicitadoErrorCmp"
              @blur="$v.monto_solicitado_var.$touch()"
              clearable
              label="Monto total de la disminución *"
              v-model="monto_solicitado_var"
            />
          </v-col>
        </v-row>

        <!-- actions -->
        <v-row cols="12" class="d-flex flex-column flex-sm-row mx-sm-4 mb-6">
          <!-- boton que cierra el modal -->
          <v-btn
            class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="secondary"
            outlined
            @click="show_modal_var = false"
          >
            Cancelar
          </v-btn>

          <!-- boton que crea la solicitud -->
          <v-btn
            class="button-extra text-no-style mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="secondary"
            @click="UpdateUnidadFtn"
          >
            Actualizar fondos de unidad
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
    
<script>
// importacion de componentes globales
import MoneyTextFieldComponent from "../../../components/MoneyTextFieldComponent.vue";
import DataTableComponent from "../../../components/DataTableComponent.vue";

// importacion de librerias
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    MoneyTextFieldComponent,
    DataTableComponent,
  },

  props: {
    info_unidad: {
      type: Object,
      default: {},
    },
  },

  validations: {
    monto_solicitado_var: {
      required,
    },
  },

  data: () => ({
    show_modal_var: false,
    monto_solicitado_var: null,
  }),

  computed: {
    montoSolicitadoErrorCmp() {
      const errors = [];
      if (!this.$v.monto_solicitado_var.$dirty) return errors;
      !this.$v.monto_solicitado_var.required &&
        errors.push("El monto es requerido");
      return errors;
    },
  },

  methods: {
    async UpdateUnidadFtn() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      } else {

        const { data, status } =
          await this.services.CifradosPresupuestarios.putUpdateUnidad(
            this.info_unidad?.["FinanciamientoUnidads.id"],
            { monto: Number(this.monto_solicitado_var) }
          );

        if (status === 200) {
          this.$emit("refresh");
          this.temporalAlert({
            message: data.message,
            show: true,
            type: "success",
          });
          this.show_modal_var = false;
        }

      }
    },
  },

  watch: {
    show_modal_var(value_param) {
      if (!value_param) {
        this.monto_solicitado_var = null;
        this.$refs.REF_MONEY_CONST.updateValue(null);
        this.$v.$reset();
      }
    },
  },
};
</script>