<template>
  <section class="mx-3">
    <v-flex>
      <!-- title -->
      <v-row class="align-center" style="height: 70px">
        <v-col cols="12" md="6">
          <p class="text-h6 mb-0 MainTitle">Unidades</p>
          <small class="my-0 primarioMinsal">Institución</small>
          <p class="primarioMinsal">{{ institucion?.nombre }}</p>
        </v-col>
      </v-row>

      <!-- search -->
      <v-row class="pt-5" style="margin-bottom: 0px">
        <div class="col-md-10" style="padding-bottom: 0px"></div>
        <div
          class="col-md-2"
          style="
            text-align: right;
            padding-bottom: 0px;
            padding-top: 0px;
            height: 50px;
          "
        >
          <v-btn
            class="secondary my-5"
            dark
            @click="agregarUnidadDialog = true"
          >
            Agregar
          </v-btn>
        </div>
      </v-row>

      <!-- table -->
      <v-row>
        <v-col cols="12" sm="5">
          <span class="secondary--text"> Listado de unidades </span>
        </v-col>
        <v-col cols="12" sm="2" class="d-flex justify-center justify-sm-end">
          <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
          <v-select
            :items="aniosFiscales"
            item-value="id"
            item-text="anio"
            label="Año"
            outlined
            @input="buscarPorAnio()"
            hide-details="auto"
            v-model="anioSelected"
          />
        </v-col>
        <v-col cols="12" sm="5" class="d-flex justify-center justify-sm-end">
          <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
          <v-text-field
            v-model="search"
            label="Búsqueda"
            outlined
            @input="buscarUnidad()"
            hide-details="auto"
          />
        </v-col>
        <v-col cols="12" md="12" class="pt-0">
          <DataTableComponent
            :headers="headers"
            :items="records"
            @paginar="paginate"
            v-models:pagina="paginaModel"
            v-models:select="porPaginaModel"
            :total_registros="filters.total_rows"
            class="mb-12"
          >
            <template #[`item.monto_asignado`]="{ item }">
              <span v-if="item.monto_asignado === null"> - </span>
              <span v-else>
                {{
                  Intl.NumberFormat("en-US", {
                    currency: "USD",
                    minimumFractionDigits: 2,
                  }).format(item.monto_asignado)
                }}
              </span>
            </template>

            <template #[`item.monto_disponible`]="{ item }">
              <span v-if="item.monto_disponible === null"> - </span>
              <span v-else>
                {{
                  Intl.NumberFormat("en-US", {
                    currency: "USD",
                    minimumFractionDigits: 2,
                  }).format(item.monto_disponible)
                }}
              </span>
            </template>

            <template v-slot:[`item.acciones`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    v-on:click="sendToDelete(item.id)"
                    data-value="item"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span> Eliminar </span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    @click="
                      ($refs.REF_MODAL_EDIT_CONST.show_modal_var = true),
                        setIdSelectUnidad(item.id)
                    "
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span> Editar </span>
              </v-tooltip>
            </template>
          </DataTableComponent>
        </v-col>
      </v-row>
    </v-flex>

    <!-- modal de agregar unidad -->
    <v-dialog v-model="agregarUnidadDialog" persistent max-width="800px">
      <v-card>
        <div class="headerIcon">
          <v-icon color="secondary" @click="agregarUnidadDialog = false">
            mdi-close
          </v-icon>
        </div>
        <v-card-text>
          <v-row class="mx-sm-6">
            <v-col cols="12">
              <span class="text-h6 secondary--text">Agregar Unidad</span>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Nombre de unidad*"
                v-model="nombreUnidades"
                :maxlength="50"
                outlined
                @blur="$v.nombreUnidades.$touch()"
                :error-messages="nombreUnidadErrors"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                label="Tipo de unidad *"
                v-model="tipo_unidad_var"
                :items="ctl_mdl_tipo_unidad_var"
                item-text="nombre"
                item-value="id"
                outlined
                clearable
                @blur="$v.tipo_unidad_var.$touch()"
                :error-messages="tipoUnidadErrors"
              />
            </v-col>
            <template v-if="tipo_unidad_var == 4">
              <v-col cols="12" class="d-flex flex-column flex-sm-row">
                <v-autocomplete
                  :items="unidadText"
                  v-model="mdl_unidad_var"
                  item-text="nombre"
                  item-value="id"
                  return-object
                  label="División, Unidad, Departamento o Área a la que pertenece *"
                  outlined
                  clearable
                />
                <v-btn
                  :disabled="mdl_unidad_var === null"
                  class="text-capitalize ml-sm-8 mx-auto"
                  color="secondary"
                  dark
                  :fab="$vuetify.breakpoint.smAndUp"
                  @click="addUnidadModalAddFtn"
                >
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="headers_unidad"
                  :items="stack_unidad_var"
                  hide-default-footer
                  disable-pagination
                >
                  <template #[`item.actions`]="{ item }">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          color="btnMinsal"
                          @click="deleteUnidadModalFtn(item.id)"
                          dark
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </template>
            <v-col cols="12" class="d-flex justify-space-around">
              <v-btn
                outlined
                color="secondary"
                class="text-capitalize px-4"
                @click="agregarUnidadDialog = false"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="secondary"
                class="text-capitalize px-6"
                @click="SaveData"
              >
                Aceptar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <modal-edit-unidad-component
      ref="REF_MODAL_EDIT_CONST"
      @updateUnidadEvent="resetPaginate"
      :id-institucion="idInstitucion"
      
    />

    <ConfirmationDialog
      title="¿Desea remover la unidad seleccionada?"
      message="De confirmar la siguiente acción los cambios no serán reversibles"
      :show="deleteUnidadDialog"
      @confirm="Delete"
      @close="deleteUnidadDialog = false"
      :cornerClose="true"
    />
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import DataTableComponent from "../../components/DataTableComponent.vue";
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";

import ModalEditUnidadComponent from "./components/ModalEditUnidadComponent.vue";

export default {
  components: {
    DataTableComponent,
    ConfirmationDialog,
    ModalEditUnidadComponent,
  },

  validations: {
    nombreUnidades: {
      required,
    },
    tipo_unidad_var: {
      required,
    },
  },

  data: () => ({
    on: null,
    attrs: null,
    agregarUnidadDialog: false,
    deleteUnidadDialog: false,
    search: "",
    aniosFiscales: [],
    aniosFiscalesCargando: false,
    anioSelected: 2,
    nombreUnidades: null,
    recordToDelete: null,
    filtros: {
      page: null,
      per_page: null,
      nombre: null,
      id_institucion: null,
      anio: null,
    },
    headers: [
      {
        text: "Nº",
        align: "end",
        sortable: false,
        value: "correlativo",
      },
      {
        text: "Unidad",
        align: "start",
        sortable: false,
        value: "nombre",
      },
      {
        text: "Monto asignado ($)",
        align: "end",
        sortable: false,
        value: "monto_asignado",
      },
      {
        text: "Monto disponible ($)",
        align: "end",
        sortable: false,
        value: "monto_disponible",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "acciones",
      },
    ],
    headers_unidad: [
      {
        text: "Nombre",
        align: "start",
        value: "nombre",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "actions",
      },
    ],

    requiredNombreUnidad: true,
    institucion: {},

    mdl_unidad_var: null,
    stack_unidad_var: [],
    ctl_mdl_unidad_var: [],
    ctl_mdl_tipo_unidad_var: [],
    estado_unidad_var: false,
    tipo_unidad_var: null,

    idInstitucion: null,
  }),

  computed: {
    ...mapState("techosPresupuestariosStore", ["records", "filters"]), // accede al archivo store/index.js

    porPaginaModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.setFiltroPorPagina(value);
      },
    },
    paginaModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.setFiltroPage(value);
      },
    },

    nombreUnidadErrors() {
      const errors = [];
      if (!this.$v.nombreUnidades.$dirty) return errors;
      !this.$v.nombreUnidades.required &&
        errors.push("El nombre de la unidad es requerido");
      return errors;
    },
    tipoUnidadErrors() {
      const errors = [];
      if (!this.$v.tipo_unidad_var.$dirty) return errors;
      !this.$v.tipo_unidad_var.required &&
        errors.push("El tipo de la unidad es requerido");
      return errors;
    },
    unidadText() {
      const unidades = this.ctl_mdl_unidad_var.map((unidad) => {
        return {
          id: unidad.id,
          nombre: `${unidad?.nombre} - ${unidad?.instituciones?.nombre}`
        }
      })
      return unidades;
    },
  },

  methods: {
    ...mapActions("techosPresupuestariosStore", [
      "setingsRecords",
      "guardarUnidades",
      "getInstitucion",
    ]),
    ...mapMutations("techosPresupuestariosStore", ["setFilters"]),

    ...mapMutations("cifradosPresupuestarios", ["setIdSelectUnidad"]),

    paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      this.filtros.page = pagina;
      this.filtros.per_page = cantidad_por_pagina;
      this.setingsRecords(this.filtros);
    },

    sendToDelete(RecordId) {
      this.recordToDelete = RecordId;
      this.deleteUnidadDialog = true;
    },

    addUnidadModalAddFtn() {
      // verificar si ya existe en el stack
      const exist = this.stack_unidad_var.find(
        (item) => item.id === this.mdl_unidad_var.id
      );
      if (exist) {
        this.temporalAlert({
          show: true,
          message: "Unidad ya agregada.",
          type: "warning",
        });
        return;
      }

      this.stack_unidad_var.push(this.mdl_unidad_var);
      this.mdl_unidad_var = null;
    },

    deleteUnidadModalFtn(id_unidad) {
      this.stack_unidad_var = this.stack_unidad_var.filter(
        (item) => item.id !== id_unidad
      );
    },

    resetPaginate() {
      this.search = null;
      this.setingsRecords({ ...this.filtros, page: 1, per_page: 10 });
    },

    async timeout(ms = 500) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      return new Promise((resolve) => {
        this.searchTimer = setTimeout(resolve, ms);
      });
    },

    async buscarUnidad() {
      await this.timeout();
      this.filtros.nombre = this.search;
      this.filtros.page = 1;
      this.filtros.per_page = 10;
      await this.setingsRecords(this.filtros);
    },

    async buscarPorAnio() {
      await this.timeout();
      const anioSeleccionadoId = this.anioSelected || 2;
      const anioSeleccionadoObj = this.aniosFiscales.find(anio => anio.id === anioSeleccionadoId);
      if (anioSeleccionadoObj) {
        this.filtros.anio = anioSeleccionadoObj.anio;
        this.filtros.page = 1;
        this.filtros.per_page = 10;
        await this.setingsRecords(this.filtros);
      }
    },

    async SaveData() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }

      if (this.tipo_unidad_var == 4) {

        const { data, status } =
          await this.services.UnidadesServices.postUnidadesNewData({
            nombre: this.nombreUnidades,
            id_tipo_unidad: this.tipo_unidad_var,
            unidades: this.stack_unidad_var,
            id_institucion: this.idInstitucion,
          });

        if (status == 201) {
          this.temporalAlert({
            message: data.message,
            show: true,
            type: "success",
          });
          this.agregarUnidadDialog = false;
          this.resetPaginate();
        }

      } else {
        await this.guardarUnidades({
          nombre: this.nombreUnidades,
          id_tipo_unidad: this.tipo_unidad_var,
          id_institucion: this.idInstitucion,
        });
        this.agregarUnidadDialog = false;
        this.resetPaginate();
        this.temporalAlert({
          show: true,
          message: "Registro guardado exitosamente.",
          type: "success",
        });
        return;
      }
    }, // end savedata function

    async Delete(id, params = {}) {
      const response = await this.services.UnidadesServices.deleteUnidades(
        this.recordToDelete
      );
      this.temporalAlert({
        show: true,
        message: "Registro eliminado exitosamente.",
        type: "success",
      });
      this.setingsRecords(this.filtros); //actions.js
      this.deleteUnidadDialog = false;
    },

    async obtenerDatosInstitucion() {
      const { data, status } = await this.getInstitucion(this.$route.params.id);
      if (status === 200) {
        this.institucion = data;
      }
    },

    async FetchUnidadesNotCldFtn() {
      const { data, status } =  await this.services.UnidadesServices.getUnidNotConsl(this.idInstitucion);

      if (status === 200) this.ctl_mdl_unidad_var = data;
    },

    async FetchTipoUnidadFtn() {

      const { data, status } =
        await this.services.UnidadesServices.getTipoUnidad();
      if (status === 200) {
        this.ctl_mdl_tipo_unidad_var = data;
      }

    },
    async cargarAniosFiscales() {
      this.aniosFiscalesCargando = true;
      try {
        const { data } = await this.services.DocumentosAniosFiscalServices.cargarAniosFiscales();
        this.aniosFiscales = data;
      } catch (error) { }
      finally {
        this.aniosFiscalesCargando = false;
      }
    },
  }, // end METHODS

  watch: {
    agregarUnidadDialog(val) {
      if (!val) {
        this.nombreUnidades = null;
        this.tipo_unidad_var = null;
        this.$v.$reset();
      }
    },

    tipo_unidad_var() {
      this.stack_unidad_var = [];
      this.mdl_unidad_var = null;
    },
  },

  created() {
    this.filtros.id_institucion = this.$route.params.id;
    this.idInstitucion = this.$route.params.id;
    
    this.obtenerDatosInstitucion();
    this.setingsRecords(this.filtros); //actions.js
    this.FetchUnidadesNotCldFtn();
    this.FetchTipoUnidadFtn();
    this.cargarAniosFiscales();
    this.buscarPorAnio();
  }, // end CREATED
};
</script>
