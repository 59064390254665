var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('p',{staticClass:"text-h6 primary--text"},[_vm._v("Unidades")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","hide-details":"","label":"Institución","placeholder":"Ingrese el nombre de la institución","outlined":"","clearable":""},on:{"keyup":_vm.ObserverInputFtn},model:{value:(_vm.search_prov_var),callback:function ($$v) {_vm.search_prov_var=$$v},expression:"search_prov_var"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('DataTableComponent',{directives:[{name:"models",rawName:"v-models:pagina",value:(_vm.paginaModel),expression:"paginaModel",arg:"pagina"},{name:"models",rawName:"v-models:select",value:(_vm.porPaginaModel),expression:"porPaginaModel",arg:"select"}],attrs:{"headers":_vm.headers,"items":_vm.instituciones,"total_registros":_vm.filters.total_rows,"table_title":"Listado de instituciones"},on:{"paginar":_vm.paginate},scopedSlots:_vm._u([{key:`item.correlativo`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.firstElement( _vm.instituciones .map((col) => col.id) .reverse() .indexOf(item.id) + 1 ) || "")+" ")]}},{key:`item.monto_asignado`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.monto_asignado !== null ? Intl.NumberFormat("en-US", { currency: "USD", minimumFractionDigits: 2, }).format(item.monto_asignado) : "-")+" ")]}},{key:`item.monto_disponible`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.monto_asignado !== null ? Intl.NumberFormat("en-US", { currency: "USD", minimumFractionDigits: 2, }).format(item.monto_disponible) : "-")+" ")]}},{key:`item.acciones`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":{
                  name: 'techos-presupuestarios-unidades',
                  params: { id: item.id },
                }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-cog-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Configuración")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":{
                  name: 'techos-presupuestarios-asignacion',
                  params: { id_institucion: item.id },
                }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-currency-usd")])],1)]}}],null,true)},[_c('span',[_vm._v("Asignación de techo")])])]}}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }