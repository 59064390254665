<template>
  <section>
    <v-card>
      <v-card-title>
        <span class="text-h5" v-text="'Asignación de fondos a unidad'" />
      </v-card-title>
      <v-divider />

      <v-card-text>
        <v-form ref="formAsignacionFondo" lazy-validation>
          <div class="px-4">
            <v-autocomplete
              v-model="cifradoPresupuestario"
              :items="cifradosList"
              item-text="cifrado_presupuestario"
              item-value="id"
              dense
              outlined
              clearable
              return-object
              label="Cifrado presupuestario *"
              :rules="[(v) => !!v || 'Este campo es requerido']"
            />
            <div class="pb-8" v-if="cifradoPresupuestario != null">
                <v-card color="secondary" class="white--text py-4 text-center">
                  <b v-text="'Monto disponible:'" />
                  {{
                    Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(cifradoPresupuestario?.monto_disponible)
                  }}
                </v-card>
            </div>

            <v-autocomplete
              v-model="unidad"
              :items="unidadList"
              item-value="id"
              :item-text="unidadText"
              dense
              outlined
              clearable
              return-object
              label="Unidad *"
              :rules="[(v) => !!v || 'Este campo es requerido']"
            />
            <v-text-field
              ref="txtMontoRef"
              min="0"
              dense
              outlined
              v-mask="currencyMask"
              prefix="$"
              label="Monto *"
              v-model="monto"
              :rules="montoRules"
              :readonly="cifradoPresupuestario === null"
            />

            <v-alert type="warning" v-if="mostrarAlerta">
              {{ alertaMonto }}
            </v-alert>
          </div>
        </v-form>
      </v-card-text>

      <v-divider />
      <v-card-actions class="px-8 py-4">
        <v-spacer />
        <v-btn
          text
          class="px-16 text-no-style"
          @click="cerrarModal()"
          v-text="'Cancelar'"
        />
        <v-btn
          color="secondary"
          class="white--text px-8 text-no-style"
          @click="showValidacionPrevia()"
          v-text="'Guardar registro'"
        />
      </v-card-actions>
    </v-card>

    <v-dialog v-model="validacionPrevia" width="500">
      <v-card>
        <v-card-text>
          <div class="pt-8 text-center" v-if="unidad !== null">
            <span class="text-h6 black--text">
              Se asignará a la <b>unidad</b>: <br />
              <span v-text="unidad.nombre" />
              <br />
              <b>la cantidad de:</b> <br />
              {{
                Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(monto?.replaceAll(",", ""))
              }}
            </span>
            <br />
            <br />
            <span
              class="text-h6"
              v-text="'¿Desea continuar con la asignación?'"
            />
          </div>
        </v-card-text>

        <v-divider />

        <v-card-actions class="justify-center">
          <v-btn
            text
            class="px-16 text-no-style"
            @click="validacionPrevia = false"
            v-text="'Cancelar'"
          />
          <v-btn
            color="secondary"
            class="white--text px-8 text-no-style"
            @click="guardarAsignacionFondosUnidad()"
            :loading="guardando"
            v-text="'Continuar'"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapState } from "vuex";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

export default {
  name: "AsignacionFondosUnidadComponent",
  props: {
    idInstitucion: { type: [String, Number] },
  },
  data: () => ({
    monto: 0,
    montoRules: [
      (v) => !!v || "Este campo es requerido",
      (v) =>
        (!!v && Number(v.replaceAll(",", "")) > 0) ||
        "Este campo debe ser mayor a 0 ",
      (v) =>
        /^-?\d|,((\.[0-9]{0,2})?)$/.test(v) ||
        "Debe ingresar un valor monetario válido",
    ],
    validacionPrevia: false,
    guardando: false,
    cifradosList: [],
    cifradoPresupuestario: null,
    institucion: null,
    unidadList: [],
    unidad: null,
    mostrarAlerta: false,
    alertaMonto: null,
    loading:false
  }),
  computed: {
    unidadText() {
      return unidadList => `${unidadList.nombre} - ${unidadList.institucion}`;
    },
    ...mapState(["selectedUnidad"]),
    currencyMask() {
      if (this.cifradoPresupuestario) {
        return createNumberMask({
          prefix: "",
          allowDecimal: true,
          includeThousandsSeparator: true,
          allowNegative: false,
        });
      } else {
        return "";
      }
    },
  },
  methods: {
    showValidacionPrevia() {
      if (this.$refs.formAsignacionFondo.validate()) {
        if (
          Number(this.monto.replaceAll(",", "")) <=
          Number(this.cifradoPresupuestario.monto_disponible).toFixed(2)
        ) {
          this.mostrarAlerta = false;
          this.validacionPrevia = true;
        } else {
          this.mostrarAlerta = true;
          this.alertaMonto =
            "Por favor ingresa un monto menor o igual al disponible en el presupuesto";
        }
      }
    },
    cerrarModal() {
      this.$emit("cerrarModal", true);
      this.$refs.txtMontoRef.reset();
      this.monto = null;
      this.validacionPrevia = false;
      this.guardando = false;
      this.cifradoPresupuestario = null;
      this.institucion = null;
      this.unidad = null;
      this.mostrarAlerta = false;
      this.alertaMonto = null;
    },
    async getCifradosPresupuestarios() {
      const filters = {};

      if (!this.haveRole("ROLE_UFI", "ROLE_UFI_TECNICO")) return;
        
      const response =  await this.services.CifradosPresupuestarios.getCifradosPresupuestarios(
        this.idInstitucion,
        { ...filters, pagination: false},
      );

      if (response.status === 200){
        this.cifradosList = response.data.map((element) => ({
          ...element,
          cifrado_presupuestario: `${element.tipo_fondo == 1 ? 'Proyecto' : 'Votado'} - ${element.cifrado_presupuestario}`
        }));
      } 
    },
    async getUnidades() {
      const response = await this.services.UnidadesServices.getAllUnidades({
        id_institucion: this.idInstitucion,
      });

      if (response.status === 200) this.unidadList = response.data;
    },
    async guardarAsignacionFondosUnidad() {

      const response =
        await this.services.CifradosPresupuestarios.postAsignacionFondoUnidad({
          id_unidad: this.unidad.id,
          id_fondo: this.cifradoPresupuestario.id,
          monto: Number(this.monto.replaceAll(",", "")),
        }).catch(() => {
          this.guardando = false;
          this.validacionPrevia = false;

        });

      if (response.status === 201) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: `Se han asignado los fondos a la unidad: ${this.unidad.nombre} - ${this.unidad.institucion}`,
        });
        this.cerrarModal();

        this.$emit("cargarFinanciamientoUnidad");
      }
    },
  },
  watch:{
    cifradoPresupuestario(){
      this.getCifradosPresupuestarios()
    }
  },
  created() {
    this.getCifradosPresupuestarios();
    this.getUnidades();
  },
};
</script>
