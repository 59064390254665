<template>
  <section>
    <p class="text-h6 primary--text">Unidades</p>
    <v-row>
      <v-col cols="12" sm="8" md="6">
        <v-text-field
          @keyup="ObserverInputFtn"
          append-icon="mdi-magnify"
          hide-details
          label="Institución"
          placeholder="Ingrese el nombre de la institución"
          outlined
          v-model="search_prov_var"
          clearable
        />
      </v-col>
      <v-col cols="12">
        <DataTableComponent
          :headers="headers"
          @paginar="paginate"
          :items="instituciones"
          v-models:pagina="paginaModel"
          v-models:select="porPaginaModel"
          :total_registros="filters.total_rows"
          table_title="Listado de instituciones"
        >
          <template v-slot:[`item.correlativo`]="{ item }">
            {{
              firstElement(
                instituciones
                  .map((col) => col.id)
                  .reverse()
                  .indexOf(item.id) + 1
              ) || ""
            }}
          </template>
          <template v-slot:[`item.monto_asignado`]="{ item }">
            {{
              item.monto_asignado !== null
                ? Intl.NumberFormat("en-US", {
                    currency: "USD",
                    minimumFractionDigits: 2,
                  }).format(item.monto_asignado)
                : "-"
            }}
          </template>
          <template v-slot:[`item.monto_disponible`]="{ item }">
            {{
              item.monto_asignado !== null
                ? Intl.NumberFormat("en-US", {
                    currency: "USD",
                    minimumFractionDigits: 2,
                  }).format(item.monto_disponible)
                : "-"
            }}
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  :to="{
                    name: 'techos-presupuestarios-unidades',
                    params: { id: item.id },
                  }"
                >
                  <v-icon color="primary">mdi-cog-outline</v-icon>
                </v-btn>
              </template>
              <span>Configuración</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  :to="{
                    name: 'techos-presupuestarios-asignacion',
                    params: { id_institucion: item.id },
                  }"
                >
                  <v-icon color="primary">mdi-currency-usd</v-icon>
                </v-btn>
              </template>
              <span>Asignación de techo</span>
            </v-tooltip>
          </template>
        </DataTableComponent>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DataTableComponent from "../../components/DataTableComponent.vue";
export default {
  name: "techos-presupuestarios",
  components: {
    DataTableComponent,
  },
  data() {
    return {
      headers: [
        {
          text: "N°",
          align: "end",
          value: "correlativo",
          sortable: false,
        },
        {
          text: "Institución",
          align: "start",
          value: "nombre",
          sortable: false,
        },
        {
          text: "Monto asignado ($)",
          align: "end",
          value: "monto_asignado",
          sortable: false,
        },
        {
          text: "Monto disponible ($)",
          align: "end",
          value: "monto_disponible",
          sortable: false,
        },
        {
          text: "Acciones",
          align: "center",
          value: "acciones",
          sortable: false,
        },
      ],

      search_prov_var: null,
      timer_var: null,
    };
  },
  computed: {
    ...mapState("techosPresupuestariosStore", ["instituciones", "filters"]),

    porPaginaModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.setFiltroPorPagina(value);
      },
    },
    paginaModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.setFiltroPage(value);
      },
    },
  },

  methods: {
    ...mapActions("techosPresupuestariosStore", ["getInstituciones"]),

    paginate(filters_params) {
      let params = {
        page: filters_params.pagina,
        per_page: filters_params.cantidad_por_pagina,
        nombre: this.search_prov_var || undefined,
      };
      this.getInstituciones(params);
    },
    showRestElements() {
      const restos = this.paginaModel * this.porPaginaModel;
      if (restos <= this.filters.total_rows) return restos;
      else return restos - (restos - this.filters.total_rows);
    },
    firstElement(value) {
      return this.showRestElements() - value + 1;
    },

    //  Funcion que limpia el timer
    CleanTimerFtn() {
      if (this.timer_var !== null) clearInterval(this.timer_var);
    },

    //  Funcion que se ejecuta cada vez que se presiona una tecla en el input de busqueda
    ObserverInputFtn() {
      this.CleanTimerFtn();
      this.timer_var = setInterval(this.SearchProviderFtn, 500);
    },

    //  Funcion que realiza la solicitud de busqueda de proveedores, ademas de mostrar y ocultar el loader ( prototype global )
    async SearchProviderFtn() {

      this.CleanTimerFtn();
      const filters_scp_var = {
        page: 1,
        per_page: 10,
        nombre: this.search_prov_var || undefined,
      };

      //  Se realiza la solicitud de busqueda de proveedores ( a traves de la accion FetchListProvSancionAc )
      await this.getInstituciones(filters_scp_var);

    },
  },

  created() {
    this.getInstituciones();
  },
};
</script>
