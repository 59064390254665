<template>
  <section>
    <h2>Configuración de cifrados presupuestarios</h2>
    <v-divider class="my-8"></v-divider>
    <v-tabs
      v-model="tab"
      class="mb-8 v-step-0"
      background-color="transparent"
      color="basil"
      grow
    >
      <v-tab :value="1" @click="getCifradosPresupuestarios()"
        >Instituciones</v-tab
      >
      <v-tab :value="2">Unidades</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item class="py-4">
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <v-btn
              block
              color="secondary"
              class="white--text v-step-1"
              @click="
                (configurarCifrado = true),
                  $refs.modal_config_cifr?.FetchListFondos()
              "
            >
              <v-icon class="white--text">mdi-plus</v-icon> cifrado
              presupuestario
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="mt-6">
          <v-col cols="12" sm="3">
            <v-text-field
              label="Buscar por cifrado"
              v-model="filters.cifrado"
              maxLength="50"
              outlined
              hide-details
              clearable
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-autocomplete
              label="Tipo de cifrado"
              :items="[
                { id: 1, name: 'Proyecto' },
                { id: 2, name: 'Votado' },
              ]"
              item-value="id"
              item-text="name"
              v-model="filters.id_tipo_cifrado"
              maxLength="50"
              outlined
              hide-details
              clearable
            />
          </v-col>
          
          <v-col cols="12" sm="2">
            <v-autocomplete
            :items="aniosFiscales"
            item-value="id"
            item-text="anio"
            label="Año"
            outlined
            hide-details
            clearable
            v-model="filters.anioSelected"
          />
          </v-col>

          <v-col cols="12" sm="1">
            <v-btn
              block
              color="secondary"
              height="100%"
              @click="getCifradosPresupuestarios(true, true)"
            >
              <v-icon color="white">mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <data-table-component
          :headers="headers"
          :items="cifradosList"
          v-models:select="perPageModel"
          v-models:pagina="pageModel"
          :total_registros="filters.total_rows"
          @paginar="paginar"
          mobile_breakpoint="1100"
          class="v-step-2"
        >
          <template v-slot:[`item.cifrado_presupuestario`]="{ item }">
            <span class="d-block my-2">{{ item.cifrado_presupuestario }}</span>
            <span
              v-if="item.tipo_fondo === 1 && item.id_fondo_votado?.id"
              class="d-block mb-2"
            >
              <b>Relacionado a:</b>
              {{ item.id_fondo_votado?.cifrado_presupuestario }}
            </span>
          </template>
          <template v-slot:[`item.monto_asignado`]="{ item }">
            {{
              Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(item.monto_asignado)
            }}
          </template>
          <template v-slot:[`item.monto_disponible`]="{ item }">
            {{
              Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(item.monto_disponible)
            }}
          </template>
          <template v-slot:[`item.tipo_fondo`]="{ item }">
            {{ item.tipo_fondo == 1 ? "Proyecto" : "Votado" }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <!-- ASIGNAR MÁS FONDOS -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="
                    (select_cifrado = item),
                      ($refs.modal_upd_cifr.show_modal_var = true)
                  "
                >
                  <v-icon color="secondary">mdi-currency-usd</v-icon>
                </v-btn>
              </template>
              <span> Refinanciar cifrado</span>
            </v-tooltip>

            <!-- ELIMINAR CIFRADO -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="
                    (select_cifrado = item), (dialogConfirmarEliminarCF = true)
                  "
                >
                  <v-icon color="secondary">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span> Eliminar cifrado </span>
            </v-tooltip>

            <!-- DISMINUIR FONDOS -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="
                    (select_cifrado = item),
                      ($refs.modal_cambiar_monto_cifr.show_modal_var = true)
                  "
                >
                  <v-icon color="secondary">mdi-currency-usd-off</v-icon>
                </v-btn>
              </template>
              <span> Reducir fondos</span>
            </v-tooltip>

            <!-- EDITAR CIFRADO -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="getCifrado(item.id)"
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon color="secondary">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span> Editar cifrado</span>
            </v-tooltip>
          </template>
        </data-table-component>
      </v-tab-item>

      <v-tab-item class="py-4">
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <v-btn
              block
              color="secondary"
              class="white--text v-step-2"
              @click="
                (configurarFondosUnidad = true),
                  getCifradosPresupuestarios(false)
              "
            >
              <v-icon class="white--text">mdi-plus</v-icon> Asignar fondos
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="3">
            <v-text-field
              label="Buscar por cifrado"
              v-model="filtersAsignacionUnidad.cifrado"
              maxLength="50"
              outlined
              hide-details
              clearable
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-autocomplete
              v-model="filtersAsignacionUnidad.id_unidad"
              :items="unidadList"
              :item-text="unidadText"
              item-value="id"
              outlined
              clearable
              hide-details
              label="Unidad *"
            />
          </v-col>

          <v-col cols="12" sm="2">
            <v-autocomplete
            :items="aniosFiscales"
            item-value="id"
            item-text="anio"
            label="Año"
            outlined
            hide-details
            clearable
            v-model="filtersAsignacionUnidad.anioSelected"
          />
          </v-col>

          <v-col cols="12" sm="1">
            <v-btn
              block
              color="secondary"
              height="100%"
              @click="getAsignacionesFondosUnidades(true)"
            >
              <v-icon color="white">mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <data-table-component
          :headers="headersFondosUnidad"
          :items="fondosAsignadosList"
          mobile_breakpoint="1100"
          v-models:select="perPageModelAsignacionUnidad"
          v-models:pagina="pageModelAsignacionUnidad"
          :total_registros="filtersAsignacionUnidad.total_rows"
          @paginar="paginarAsignacionUnidad"
        >
          <template v-slot:[`item.cifrado_presupuestario`]="{ item }">
            <span class="d-block my-2">{{ item.cifrado_presupuestario }}</span>
            <span
              v-if="item.tipo_fondo === 1 && item.cifrado_votado"
              class="d-block mb-2"
            >
              <b>Relacionado a:</b> {{ item.cifrado_votado }}
            </span>
          </template>
          <template v-slot:[`item.monto_asignado`]="{ item }">
            {{
              Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(item.monto_asignado)
            }}
          </template>
          <template v-slot:[`item.monto_disponible`]="{ item }">
            {{
              Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(item.monto_disponible)
            }}
          </template>
          <template v-slot:[`item.tipo_fondo`]="{ item }">
            {{ item.tipo_fondo == 1 ? "Proyecto" : "Votado" }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="
                    (select_unidad = item),
                      ($refs.modal_upd_und.show_modal_var = true)
                  "
                >
                  <v-icon color="secondary">mdi-currency-usd-off</v-icon>
                </v-btn>
              </template>
              <span> Disminución de fondo disponible</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="
                    (select_unidad = item),
                      (dialogEliminacionFondoUnidad = true)
                  "
                >
                  <v-icon color="secondary">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span> Eliminar asignación de fondos</span>
            </v-tooltip>
          </template>
        </data-table-component>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog v-model="configurarCifrado" persistent width="600px" scrollable>
      <configuracion-cifrado-component
        ref="modal_config_cifr"
        :cifradoData="cifradoData"
        :id-institucion="idInstitucion"
        @cerrarModal="cerrarModalAction"
        @cargarCifradoPresupuestario="getCifradosPresupuestarios"
      />
    </v-dialog>

    <v-dialog
      v-model="configurarFondosUnidad"
      persistent
      width="600px"
      scrollable
    >
      <asignacion-fondos-unidad-component
        @cerrarModal="cerrarModalAsignacionFondosUnidades"
        @cargarFinanciamientoUnidad="getAsignacionesFondosUnidades"
        :id-institucion="idInstitucion"
      />
    </v-dialog>

    <update-unidad-component
      ref="modal_upd_und"
      :info_unidad="select_unidad"
      @refresh="getAsignacionesFondosUnidades"
    />

    <update-cifrado-component
      ref="modal_upd_cifr"
      :info_cifrado="select_cifrado"
      @refresh="getCifradosPresupuestarios"
    />

    <ConfirmationDialogComponent
      :show="dialogConfirmarEliminarCF"
      btnConfirmar="Eliminar"
      :title="`¿Deseas eliminar el cifrado ${select_cifrado?.cifrado_presupuestario}?`"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      @close="dialogConfirmarEliminarCF = false"
      @confirm="eliminarCifradoPresupuestario"
    />

    <cambiar-monto-cifrado-component
      ref="modal_cambiar_monto_cifr"
      :info_cifrado="select_cifrado"
      @refresh="getCifradosPresupuestarios"
    />

    <ConfirmationDialogComponent
      :show="dialogEliminacionFondoUnidad"
      btnConfirmar="Eliminar"
      :title="`¿Deseas eliminar los fondos asignados a la unidad ${select_unidad?.nombre} del cifrado ${select_unidad?.cifrado_presupuestario}?`"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      @close="dialogEliminacionFondoUnidad = false"
      @confirm="eliminarFondosAsignadosUnidad"
    />
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import DataTableComponent from "../../components/DataTableComponent.vue";
import ConfiguracionCifradoComponent from "./components/ConfiguracionCifradoComponent.vue";
import AsignacionFondosUnidadComponent from "./components/AsignacionFondosUnidadComponent.vue";
import UpdateCifradoComponent from "./components/UpdateCifradoComponent.vue";
import ConfirmationDialogComponent from "../../components/ConfirmationDialogComponent.vue";
import CambiarMontoCifradoComponent from "./components/CambiarMontoCifradoComponent.vue";
import UpdateUnidadComponent from "./components/UpdateUnidadComponent.vue";

export default {
  name: "asignacionView",
  components: {
    DataTableComponent,
    ConfiguracionCifradoComponent,
    AsignacionFondosUnidadComponent,
    UpdateCifradoComponent,
    ConfirmationDialogComponent,
    CambiarMontoCifradoComponent,
    UpdateUnidadComponent,
  },
  data: () => ({
    idInstitucion: null,
    show_modal_update_cifrado: false,
    select_cifrado: null,
    select_unidad: null,
    tab: 0,
    year_selected: null,
    aniosFiscales: [],
    aniosFiscalesCargando: false,
    anioSelected: null,
    anioSeleccionadoObj: null,
    headers: [
      {
        text: "Institución",
        align: "left",
        sortable: false,
        value: "nombre_institucion",
        width: "20%",
      },
      {
        text: "Cifrado presupuestario",
        align: "left",
        sortable: false,
        value: "cifrado_presupuestario",
        width: "25%",
      },
      {
        text: "Monto asignado",
        align: "right",
        sortable: false,
        value: "monto_asignado",
        width: "20%",
      },
      {
        text: "Monto disponible",
        align: "right",
        sortable: false,
        value: "monto_disponible",
        width: "20%",
      },
      {
        text: "Tipo de fondo",
        align: "start",
        sortable: false,
        value: "tipo_fondo",
        width: "5%",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "actions",
      },
    ],
    cifradosList: [],
    configurarCifrado: false,

    headersFondosUnidad: [
      {
        text: "Cifrado presupuestario",
        align: "left",
        sortable: false,
        value: "cifrado_presupuestario",
        width: "25%",
      },
      {
        text: "Unidad",
        align: "left",
        sortable: false,
        value: "nombre",
        width: "25%",
      },
      {
        text: "Monto asignado",
        align: "right",
        sortable: false,
        value: "monto_asignado",
        width: "20%",
      },
      {
        text: "Monto disponible",
        align: "right",
        sortable: false,
        value: "monto_disponible",
        width: "20%",
      },
      {
        text: "Tipo de fondo",
        align: "start",
        sortable: false,
        value: "tipo_fondo",
        width: "10%",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "actions",
      },
    ],
    fondosAsignadosList: [],
    configurarFondosUnidad: false,
    unidadList: [],

    dialogConfirmarEliminarCF: false,
    dialogEditarCF: false,

    dialogEliminacionFondoUnidad: false,
    cifradoData: {},
  }),
  computed: {
    unidadText() {
      return unidadList => `${unidadList.nombre} - ${unidadList.institucion}`;
    },
    ...mapState(["selectedUnidad"]),
    ...mapState("cifradosPresupuestarios", [
      "filters",
      "filtersAsignacionUnidad",
    ]),
    ...mapState({ userInfo: "userInfo" }),
    pageModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.setPage(value);
      },
    },
    perPageModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.setPerPage(value);
      },
    },

    pageModelAsignacionUnidad: {
      get() {
        return this.filtersAsignacionUnidad.page;
      },
      set(value) {
        this.setPageASignacionUnidad(value);
      },
    },
    perPageModelAsignacionUnidad: {
      get() {
        return this.filtersAsignacionUnidad.per_page;
      },
      set(value) {
        this.setPerPageASignacionUnidad(value);
      },
    },
    years() {
      return [
        {
          id: 1,
          year: 2023,
        },
      ];
    },
  },
  methods: {
    ...mapMutations("cifradosPresupuestarios", [
      "setPage",
      "setPerPage",
      "setPageASignacionUnidad",
      "setPerPageASignacionUnidad",
      "initPaginationCifrado",
      "initPaginationAsignacionUnidad",
    ]),
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.filters.page = pagina;
      this.filters.per_page = cantidad_por_pagina;
      this.getCifradosPresupuestarios();
    },
    async getCifradosPresupuestarios(pagination = true, busqueda = false) {


      if (busqueda) this.pageModel = 1;

      if (this.haveRole("ROLE_UFI", "ROLE_UFI_TECNICO")) {
        this.filters.id_institucion = this.selectedUnidad.instituciones.id;
      }
      const response =
        await this.services.CifradosPresupuestarios.getCifradosPresupuestarios(
          this.idInstitucion,  
          { pagination, ...this.filters }
        );

      if (response.status === 200) {
        if (pagination) this.initPaginationCifrado(response.headers);
        this.cifradosList = response.data;
      }

    },
    cerrarModalAction(status) {
      this.configurarCifrado = !status;
      this.cifradoData = {};
    },

    paginarAsignacionUnidad(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.filtersAsignacionUnidad.page = pagina;
      this.filtersAsignacionUnidad.per_page = cantidad_por_pagina;
      this.getAsignacionesFondosUnidades();
    },
    async getAsignacionesFondosUnidades(busqueda = false) {


      if (busqueda) this.pageModelAsignacionUnidad = 1;

      const filtros = { ...this.filtersAsignacionUnidad };

      if (this.haveRole("ROLE_UFI", "ROLE_UFI_TECNICO")) filtros.id_institucion = this.idInstitucion;

      const response = await this.services.CifradosPresupuestarios.getFinanciamientoUnidades(filtros);

      if (response.status === 200) {
        this.initPaginationAsignacionUnidad(response.headers);
        this.fondosAsignadosList = response.data;
        this.getCifradosPresupuestarios();
      }

    },
    cerrarModalAsignacionFondosUnidades(status) {
      this.configurarFondosUnidad = !status;
    },
    nextStep() {
      this.currentStep = this.currentStep + 1;
      this.$shepherd.next(this.currentStep);
      this.dataTour(this.currentStep);
    },
    backStep() {
      this.currentStep = this.currentStep - 1;
      this.$shepherd.back(this.currentStep - 1);
      this.dataTour(this.currentStep);
    },
    cancelStep() {
      this.currentStep = null;
      this.$shepherd.cancel(this.currentStep);
      this.dataTour(this.currentStep);
    },
    completeTour() {
      this.currentStep = this.currentStep + 1;
      this.$shepherd.complete(this.currentStep);
      this.dataTour(this.currentStep);
    },
    dataTour(step) {
      const ruta = this.$router.currentRoute.path.split("/");
      const stepsInfo = {
        [ruta[1]]: {
          id: this.userInfo?.user?.id,
          email: this.userInfo?.user?.email,
          step: step,
        },
      };
      localStorage.setItem("StepInfo", JSON.stringify(stepsInfo));
    },
    getInfo() {
      const info = JSON.parse(localStorage.getItem("StepInfo"))
        ? JSON.parse(localStorage.getItem("StepInfo"))
        : null;
      const ruta = this.$router.currentRoute.path.split("/");
      if (info) {
        for (let value of Object.keys(info)) {
          if (ruta[1] == value) {
            if (this.userInfo?.user?.id == info[value].id) {
              this.currentStep = info[value].step;
              this.$shepherd.show(this.currentStep);
            }
          }
        }
      } else {
        this.$shepherd.show(0);
      }
    },
    stepsShepherd() {
      this.$shepherd.addSteps([
        {
          id: "1",
          title: "Cifrados presupuestarios",
          text: "Apartado  muestra opciones institución y unidades",
          attachTo: {
            element: ".v-step-0",
            on: "bottom",
          },

          buttons: [
            {
              text: "Salir",
              action: this.cancelStep,
            },

            {
              text: "Siguiente",
              action: this.nextStep,
            },
            {
              text: "Omitir guía",
              action: this.cancelStep,
            },
          ],
          advanceOn: { selector: ".v-step-0", event: "click" },
        },
        {
          id: "2",
          title: "Cifrados presupuestarios",
          text: "Botón permite agregar una nueva configuración de cifrado presupuestario  ",
          attachTo: {
            element: ".v-step-1",
            on: "bottom",
          },
          buttons: [
            {
              text: "Anterior",
              action: this.backStep,
            },

            {
              text: "Siguiente",
              action: this.nextStep,
            },
            {
              text: "Omitir guía",
              action: this.cancelStep,
            },
          ],
        },
        {
          id: "3",
          title: "Cifrados presupuestarios",
          text: "Se listan los cifrados presupuestarios creados ",
          attachTo: {
            element: ".v-step-2",
            on: "bottom",
          },
          buttons: [
            {
              text: "Anterior",
              action: this.backStep,
            },
            {
              text: "Completar guía",
              action: this.completeTour,
            },
          ],
        },
      ]);
      this.getInfo();
    },
    async getUnidades() {
      const response = await this.services.UnidadesServices.getAllUnidades({ pagination: false, id_institucion: this.idInstitucion });

      if (response.status === 200) this.unidadList = response.data;
    },
    async eliminarCifradoPresupuestario() {

      const response =
        await this.services.CifradosPresupuestarios.deleteCifradoPresupuestario(
          this.select_cifrado.id
        );

      if (response.status === 200) {
        this.temporalAlert({
          show: true,
          message: "Cifrado presupuestario eliminado correctamente",
          type: "success",
        });
        this.getCifradosPresupuestarios(true, true);
        this.dialogConfirmarEliminarCF = false;
      }

    },
    async eliminarFondosAsignadosUnidad() {

      const response =
        await this.services.CifradosPresupuestarios.deleteFondosAsignadosUnidad(
          this.select_unidad["FinanciamientoUnidads.id"]
        );

      if (response.status === 200) {
        this.temporalAlert({
          show: true,
          message: "Fondos asignados eliminados correctamente",
          type: "success",
        });
        this.getAsignacionesFondosUnidades(true);
        this.dialogEliminacionFondoUnidad = false;
      }
    },
    async getCifrado(id_fondo) {

      const { status, data } = await this.services.Cifrados.getCifradoInfo(
        id_fondo
      );

      if (status == 200 && data) {
        this.cifradoData = data;
        this.configurarCifrado = true;
      }

    },

    async cargarAniosFiscales() {
      this.aniosFiscalesCargando = true;
      try {
        const { data } = await this.services.DocumentosAniosFiscalServices.cargarAniosFiscales();
        this.aniosFiscales = data;
      } catch (error) { }
      finally {
        this.aniosFiscalesCargando = false;
      }
    },

  },
  created() {
    this.idInstitucion = this.$route.params.id_institucion;
    this.cargarAniosFiscales();
    setTimeout(() => {
      this.getCifradosPresupuestarios();
      this.getAsignacionesFondosUnidades();
      this.getUnidades();
    }, 1500);

  },
  beforeDestroy() {
    this.filters.page = 1;
    this.filters.per_page = 10;
    this.filters.total_rows = 0;
    this.cifradosList = [];
    this.year_selected = new Date().getFullYear();
  },
};
</script>

<style scoped>
.tabStyle {
  width: 100%;
}
.theme--light.v-application .text--secondary {
  color: #404a7a !important;
}
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
</style>
