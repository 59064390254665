<template>
  <section>
    <v-card>
      <v-card-title>
        <span class="text-h5"
          >{{ Object.keys(cifradoData).length > 0 ? "Editar" : "Configurar" }} cifrado
          presupuestario</span
        >
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-form ref="formCifrado" lazy-validation>
          <div class="px-6 mb-2" v-if="Object.keys(cifradoData).length  == 0">
            <v-radio-group
              v-model="tipo_fondo"
              row
              :rules="[(v) => !!v || 'Este campo es requerido']"
            >
              <span class="mt-md-1 mr-md-3 v-label" v-text="'Tipo de fondo:'" />
              <v-radio label="Proyecto" :value="1" />
              <v-radio label="Votado" :value="2" />
            </v-radio-group>
          </div>
          <div class="px-4" v-if="tipo_fondo == 2">
            <v-autocomplete
              dense
              outlined
              label="Fondos de proyecto *"
              v-model="select_fondo_proyecto"
              :items="ctl_fondo_proyecto"
              item-text="cifrado_presupuestario"
              item-value="id"
              :rules="[ruleFondoProyect]"
            />
          </div>
          <div class="px-4" v-for="catalogo in formularioCifrado">
            <v-autocomplete
              v-model="configuracionCifrado[catalogo.id]"
              :items="depuradorCtl(catalogo?.CatalogosCifrados)"
              item-text="valor"
              item-value="id"
              dense
              outlined
              clearable
              return-object
              :label="catalogo.nombre + ' *'"
              :rules="[(v) => !!v || 'Este campo es requerido']"
              @change="generarCifradoPresupuestario"
            ></v-autocomplete>
          </div>

          <div class="px-4" v-if="cifradoPresupuestarioGenerado != ''">
            <v-card color="secondary" class="white--text py-4 text-center">
              <span class="text-h5">{{ cifradoPresupuestarioGenerado }}</span>
            </v-card>
          </div>

          <v-text-field
            min="0"
            outlined
            v-mask="currencyMask"
            prefix="$"
            label="Monto *"
            class="px-4 mt-8"
            v-model="monto"
            :rules="montoRules"
            @change="monto.valueOf()"
            v-if="cifradoPresupuestarioGenerado != '' && Object.keys(cifradoData).length == 0"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="px-8 py-4">
        <v-spacer></v-spacer>
        <v-btn text class="px-16" @click="cerrarModal()"> Cancelar </v-btn>
        <v-btn
          color="secondary"
          class="white--text px-8"
          @click="showValidacionPrevia()"
        >
          Guardar registro
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="validacionPrevia" width="500">
      <v-card>
        <v-card-text>
          <div class="pt-8 text-center">
            <span class="text-h6 black--text"
              >Se agregará el <b>cifrado presupuestario</b>: <br /><span>{{
                cifradoPresupuestarioGenerado
              }}</span>
              <br />
              Con <b>monto:</b> <br />
              {{
                Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(monto ? monto.replaceAll(",", "") : 0)
              }}</span
            >
            <br /><br />
            <span class="text-h6">¿Desea continuar con la creación?</span>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center">
          <v-btn text class="px-16" @click="validacionPrevia = false">
            Cancelar
          </v-btn>
          <v-btn
            color="secondary"
            class="white--text px-8"
            @click="guardarCifradoPresupuestario()"
            :loading="guardando"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapState } from "vuex";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

export default {
  name: "ConfiguracionCifradoComponent",
  props: {
    cifradoData: {
      type: Object,
      default: () => {},
    },
    idInstitucion: { type: [Number, String] },
  },
  data: () => ({
    tipo_fondo: null,
    configuracionCifrado: {},
    formularioCifrado: [],
    cifradoPresupuestarioGenerado: "",
    monto: 0,
    montoRules: [
      (v) => !!v || "Este campo es requerido",
      (v) =>
        (!!v && Number(v.replaceAll(",", "")) > 0) ||
        "Este campo debe ser mayor a 0 ",
      (v) =>
        /^-?\d|,((\.[0-9]{0,2})?)$/.test(v) ||
        "Debe ingresar un valor monetario válido",
    ],
    validacionPrevia: false,
    guardando: false,

    ctl_fondo_proyecto: [],
    select_fondo_proyecto: null,
  }),
  computed: {
    ...mapState(['institucionesUsuario']),
    ...mapState(["selectedUnidad"]),
    currencyMask() {
      return createNumberMask({
        prefix: "",
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
      });
    },
  },
  methods: {
    ruleFondoProyect(value) {
      if (this.tipo_fondo == 2) {
        return !!value || "Este campo es requerido";
      } else {
        return true;
      }
    },
    depuradorCtl(catalogo) {
      // verificar que sea un arreglo
      if (Array.isArray(catalogo)) {
        return catalogo;
      } else {
        // si no es un arreglo, verificar que sea un objeto y agregarlo a un arreglo
        if (typeof catalogo === "object") {
          return [catalogo];
        } else {
          // si no es un objeto, retornar un arreglo vacio
          return [];
        }
      }
    },
    showValidacionPrevia() {
      if (this.$refs.formCifrado.validate()) {
        if (Object.keys(this.cifradoData).length > 0) {
          this.updateCifradoEstructura();
        } else {
          this.validacionPrevia = true;
        }
      }
    },
    cerrarModal() {
      this.$refs.formCifrado.reset();
      this.$emit("cerrarModal", true);
      this.configuracionCifrado = {};
      this.cifradoPresupuestarioGenerado = "";
      this.monto = 0;
      this.validacionPrevia = false;
    },
    async getConfiguracionCifrado() {
      const response =
        await this.services.CifradosPresupuestarios.getConfiguracionCifrado({
          id_institucion: this.idInstitucion,
        });

      if (response.status === 200) {
        const institucionActiva = this.institucionesUsuario.find((i) => +i.id === +this.idInstitucion);

        const catalogos = response.data.map((catalogo) => {

          if (this.haveRole("ROLE_UFI", "ROLE_UFI_TECNICO") && catalogo.id === 2) {
            catalogo.CatalogosCifrados = institucionActiva
              ? catalogo.CatalogosCifrados.filter((item) => item.codigo === institucionActiva.codigo.toString())
              : [];
          }

          return catalogo;
        });

        this.formularioCifrado = catalogos;
      } else {
        this.temporalAlert({
          show: true,
          type: "error",
          message:
            "No se ha podido obtener la configuración del cifrado para tu institución",
        });
      }
    },
    generarCifradoPresupuestario() {
      this.cifradoPresupuestarioGenerado = "";

      Object.keys(this.configuracionCifrado)
        .sort((a, b) => a - b)
        .forEach((key) => {
          this.configuracionCifrado[key] = this.configuracionCifrado[key];
        });

      Object.entries(this.configuracionCifrado).forEach(([, value], key) => {
        if (value !== null) {
          this.cifradoPresupuestarioGenerado += `${value.codigo}`;
          const guion = this?.formularioCifrado[key + 1]?.guion_izquierdo
            ? "-"
            : "";
          this.cifradoPresupuestarioGenerado += `${guion}`;
        }
      });
    },
    async guardarCifradoPresupuestario() {
      const construccion = [];
      this.guardando = true;

      Object.entries(this.configuracionCifrado).forEach(([key, value]) => {
        if (value !== null) construccion.push(value.id);
      });

      if (this.$refs.formCifrado.validate()) {
        let nMonto = this.monto.split(",");
        let MontoEnvio = "";
        nMonto.map((x) => {
          MontoEnvio = MontoEnvio + x;
        });
        if (Number(MontoEnvio) <= 0) {
          this.temporalAlert({
            show: true,
            message: "El monto debe ser mayor a 0",
            type: "error",
          });
        } else {
          const response =
            await this.services.CifradosPresupuestarios.postConfiguracionCifrado(
              {
                cifrado: this.cifradoPresupuestarioGenerado,
                monto: Number(MontoEnvio),
                id_institucion: +this.idInstitucion,
                construccion: construccion,
                tipo_fondo: Number(this.tipo_fondo),
                id_fondo_proyecto:
                  this.tipo_fondo == 2 ? this.select_fondo_proyecto : undefined,
              }
            ).catch(() => {
              this.guardando = false;
              this.validacionPrevia = false;
            });

          if (response.status === 201) {
            this.temporalAlert({
              show: true,
              type: "success",
              message: "El cifrado presupuestario se ha agregado con éxito",
            });
            this.$emit("cargarCifradoPresupuestario");
            this.cerrarModal();
          }
        }
      }
      this.guardando = false;
    },

    async FetchListFondos() {

      const { data, status } =
        await this.services.PacServices.getFondosProyecto();
      if (status == 200) {
        this.ctl_fondo_proyecto = data;
      }

    },
    async updateCifradoEstructura() {
      this.guardando = true;
      const construccion = [];
      Object.entries(this.configuracionCifrado).forEach(([key, value]) => {
        if (value !== null) construccion.push(value.id);
      });

      const { status } = await this.services.Cifrados.updateCifradoInfo(
        this.cifradoData.id,
        {
          cifrado: this.cifradoPresupuestarioGenerado,
          construccion: construccion,
        }
      );

      if (status == 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "El cifrado presupuestario se ha actualizado con éxito",
        });
        this.$emit("cargarCifradoPresupuestario");
        this.cerrarModal();
      }
      this.guardando = false;
    },
  },
  watch: {
    cifradoData(newVal, oldValue) {
      newVal.cifrado?.forEach((cifrado) => {
        this.configuracionCifrado[
          cifrado.CatalogosCifrado.id_tipo_elemento_cifrado
        ] = cifrado.CatalogosCifrado;
      });
    },
  },
  async created() {
    this.FetchListFondos();
    this.getConfiguracionCifrado();

    if(Object.keys(this.cifradoData).length > 0){
      this.cifradoData.cifrado?.forEach((cifrado) => {
        this.configuracionCifrado[
          cifrado.CatalogosCifrado.id_tipo_elemento_cifrado
        ] = cifrado.CatalogosCifrado;
      });
    }
  },
};
</script>
