<template>
  <v-dialog v-model="show_modal_var" persistent max-width="700">
    <v-card>
      <!-- boton en esquina de cerrar -->
      <div class="headerIcon">
        <v-icon class="secondary--text" @click="show_modal_var = false">
          mdi-close
        </v-icon>
      </div>

      <!-- form -->
      <v-card-text>
        <v-row class="mx-sm-6">
          <v-col cols="12">
            <span
              class="text-h6 secondary--text"
              v-text="'Actualizar monto disponible de cifrado'"
            />
          </v-col>
          <v-col cols="12" class="d-flex flex-column text-center text-sm-start">
            <span> Institución </span>
            <span class="secondary--text">
              {{ info_cifrado?.nombre_institucion }}
            </span>
            <span> Cifrado </span>
            <span class="secondary--text">
              {{ info_cifrado?.cifrado_presupuestario }}
            </span>
            <span> Tipo de fondo </span>
            <span class="secondary--text">
              {{ info_cifrado?.tipo_fondo == 1 ? "Proyecto" : "Votado" }}
            </span>
            <span> Monto asignado </span>
            <span class="secondary--text">
              {{
                Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(info_cifrado?.monto_asignado)
              }}
            </span>
            <span> Monto disponible </span>
            <span class="secondary--text">
              {{
                Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(info_cifrado?.monto_disponible)
              }}
            </span>
          </v-col>
          <v-col cols="12">
            <money-text-field-component
              ref="REF_MONEY_CONST"
              :error-messages="montoSolicitadoErrorCmp"
              @blur="$v.nuevo_financiamiento_var.$touch()"
              clearable
              label="Monto a asignar *"
              v-model="nuevo_financiamiento_var"
            />
          </v-col>
        </v-row>

        <!-- actions -->
        <v-row cols="12" class="d-flex flex-column flex-sm-row mx-sm-4 mb-6">
          <!-- boton que cierra el modal -->
          <v-btn
            class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="secondary"
            outlined
            @click="show_modal_var = false"
          >
            Cancelar
          </v-btn>

          <!-- boton que crea la solicitud -->
          <v-btn
            class="button-extra text-no-style mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="secondary"
            @click="UpdateCifradoFtn"
          >
            Actualizar cifrado
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
    
<script>
// importacion de componentes globales
import MoneyTextFieldComponent from "../../../components/MoneyTextFieldComponent.vue";
import DataTableComponent from "../../../components/DataTableComponent.vue";

// importacion de librerias
import { required } from "vuelidate/lib/validators";

function validateNuevoFinanciamiento(value) {
  return Number(value) < Number(this.info_cifrado?.monto_disponible);
}

export default {
  name: "CambiarMontoCifradoComponent",
  components: {
    MoneyTextFieldComponent,
    DataTableComponent,
  },

  props: {
    info_cifrado: {
      type: Object,
      default: {},
    },
  },

  validations: {
    nuevo_financiamiento_var: {
      required,
      validateNuevoFinanciamiento,
    },
  },

  data: () => ({
    show_modal_var: false,
    nuevo_financiamiento_var: null,
  }),

  computed: {
    montoSolicitadoErrorCmp() {
      const errors = [];
      if (!this.$v.nuevo_financiamiento_var.$dirty) return errors;
      !this.$v.nuevo_financiamiento_var.required &&
        errors.push("El monto es requerido");
      !this.$v.nuevo_financiamiento_var.validateNuevoFinanciamiento &&
        errors.push("El monto no puede ser mayor al disponible. Si desea aumentar el monto disponible, debe hacerlo desde la acción correspondiente.");
      return errors;
    },
  },

  methods: {
    async UpdateCifradoFtn() {
      this.$v.$touch();
      if (!this.$v.$invalid) {

        const response =
          await this.services.CifradosPresupuestarios.cambiarMontoDisponible(
            this.info_cifrado.id,
            { monto_disponible: Number(this.nuevo_financiamiento_var) }
          );

        if (response.status === 200) {
          this.temporalAlert({
            message: 'Se ha actualizado el monto disponible del cifrado',
            show: true,
            type: "success",
          });
          this.show_modal_var = false;
          this.$emit("refresh");
        }

      }
    },
  },

  watch: {
    show_modal_var(value_param) {
      if (!value_param) {
        this.nuevo_financiamiento_var = null;
        this.$refs.REF_MONEY_CONST.updateValue(null);
        this.$v.$reset();
      }
    },
    info_cifrado(value_param) {
      if (value_param) {
        this.nuevo_financiamiento_var = Number(this.info_cifrado?.monto_disponible);
      }
    },
  },
};
</script>