<template>
  <v-dialog v-model="show_modal_var" persistent max-width="800px">
    <v-card>
      <!-- corner close -->
      <div class="headerIcon">
        <v-icon color="secondary" @click="show_modal_var = false">
          mdi-close
        </v-icon>
      </div>
      <!-- form -->
      <v-card-text>
        <v-row class="mx-sm-6">
          <v-col cols="12">
            <span class="text-h6 secondary--text">Editar Unidad</span>
          </v-col>
          <!-- campo nombre de unidad -->
          <v-col cols="12">
            <v-text-field
              label="Nombre de unidad *"
              v-model="nombre_unidad_var"
              :maxlength="50"
              outlined
              @blur="$v.nombre_unidad_var.$touch()"
              :error-messages="nombreUnidadErrorsCmp"
            />
          </v-col>
          <!-- campo tipo de unidad -->
          <v-col cols="12">
            <v-select
              label="Tipo de unidad *"
              v-model="tipo_unidad_var"
              :items="ctl_tipo_unidad_var"
              item-text="nombre"
              item-value="id"
              outlined
              clearable
              @blur="$v.tipo_unidad_var.$touch()"
              :error-messages="tipoUnidadErrorsCmp"
            />
          </v-col>
          <template v-if="tipo_unidad_var == 4">
            <!-- campo de sub unidad -->
            <v-col cols="12" class="d-flex flex-column flex-sm-row">
              <v-autocomplete
                :items="unidadText"
                v-model="sub_unidad_var"
                item-text="nombre"
                item-value="id"
                return-object
                label="División, Unidad, Departamento o Área a la que pertenece *"
                outlined
                clearable
              />
              <v-btn
                :disabled="sub_unidad_var === null"
                class="text-capitalize ml-sm-8 mx-auto"
                color="secondary"
                dark
                :fab="$vuetify.breakpoint.smAndUp"
                @click="AddSubUnidadFtn"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="HEADERS_CONST"
                :items="stack_unidad_var"
                hide-default-footer
                disable-pagination
              >
                <template #[`item.actions`]="{ item }">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="btnMinsal"
                        @click="DeleteSubUnidadFtn(item.id)"
                        icon
                        v-if="id_select_unidad_st !== item.id"
                      >
                        <v-icon> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </template>

          <v-col cols="12" class="d-flex justify-space-around">
            <v-btn
              outlined
              color="secondary"
              class="text-capitalize px-4"
              @click="show_modal_var = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="secondary"
              class="text-capitalize px-6"
              @click="UpdateUnidadFtn"
            >
              Actualizar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// importacion de librerias
import { required } from "vuelidate/lib/validators";
import { mapMutations, mapState } from "vuex";

export default {
  name: "ModalEditUnidadComponent",

  validations: {
    nombre_unidad_var: { required },
    tipo_unidad_var: { required },
  },

  props: {
    idInstitucion: { type: [Number, String] },
  },

  data: () => ({
    // constantes
    HEADERS_CONST: [
      {
        text: "Nombre",
        align: "start",
        value: "nombre",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "actions",
      },
    ],

    // variables
    show_modal_var: false,

    nombre_unidad_var: "",
    tipo_unidad_var: null,
    sub_unidad_var: null,
    stack_unidad_var: [],

    ctl_tipo_unidad_var: [],
    ctl_sub_unidad_var: [],
  }),

  computed: {
    ...mapState("cifradosPresupuestarios", ["id_select_unidad_st"]),
    // errores computados de vuex
    nombreUnidadErrorsCmp() {
      const errors = [];
      if (!this.$v.nombre_unidad_var.$dirty) return errors;
      !this.$v.nombre_unidad_var.required &&
        errors.push("El campo nombre es requerido.");
      return errors;
    },

    tipoUnidadErrorsCmp() {
      const errors = [];
      if (!this.$v.tipo_unidad_var.$dirty) return errors;
      !this.$v.tipo_unidad_var.required &&
        errors.push("El campo tipo de unidad es requerido.");
      return errors;
    },
    unidadText() {
      const unidades = this.ctl_sub_unidad_var.map((unidad) => {
        return {
          id: unidad.id,
          nombre: `${unidad?.nombre} - ${unidad?.instituciones?.nombre}`
        }
      })
      return unidades;
    },
  },

  methods: {
    ...mapMutations("cifradosPresupuestarios", ["setIdSelectUnidad"]),

    // <-- Funciones Regulares -->

    AddSubUnidadFtn() {
      const exist_scp_var = this.stack_unidad_var.find(
        (local_item) => local_item.id === this.sub_unidad_var.id
      );

      if (exist_scp_var) {
        this.temporalAlert({
          show: true,
          message: "Unidad ya agregada.",
          type: "warning",
        });
        return;
      }

      this.stack_unidad_var.push(this.sub_unidad_var);
      this.sub_unidad_var = null;
    },

    DeleteSubUnidadFtn(id_unidad_param) {
      this.stack_unidad_var = this.stack_unidad_var.filter(
        (local_item) => local_item.id !== id_unidad_param
      );
    },

    // <-- Funciones Asincronas -->

    /**
     * @description funcion para obtener las unidades no consolidadas ( API )
     * @async
     */
    async FetchUnidadesNotCldFtn() {

      const { data, status } =
        await this.services.UnidadesServices.getUnidNotConsl(
          this.idInstitucion,
          { pagination: false },
        );
      if (status === 200) {
        this.ctl_sub_unidad_var = data;
      }

    },

    /**
     * @description funcion para obtener los tipos de unidades ( API )
     * @async
     */
    async FetchTipoUnidadFtn() {

      const { data, status } =
        await this.services.UnidadesServices.getTipoUnidad();
      if (status === 200) {
        this.ctl_tipo_unidad_var = data;
      }

    },

    async FetchDetailUnidad() {

      const { data, status } =
        await this.services.UnidadesServices.getDetailUnidad(
          this.id_select_unidad_st
        );
      if (status === 200) {
        this.nombre_unidad_var = data.nombre;
        this.tipo_unidad_var = data.id_tipo_unidad;
        this.stack_unidad_var = data.unidades_hijas;
      }

    },

    async UpdateUnidadFtn() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      } else {
        if (this.tipo_unidad_var != 4) {
          this.stack_unidad_var = [];
        }


        const { status, data } =
          await this.services.UnidadesServices.putUnidadConsolidada(
            this.id_select_unidad_st,
            {
              nombre: this.nombre_unidad_var,
              id_tipo_unidad: this.tipo_unidad_var,
              unidades_hijas: this.stack_unidad_var,
            }
          );

        if (status === 200) {
          this.temporalAlert({
            show: true,
            message: data.message,
            type: "success",
          });
          this.$emit("updateUnidadEvent");
          this.show_modal_var = false;
        }

      }
    },
  },

  watch: {
    // cuando se cierra el modal, se limpian las variables
    show_modal_var(value_param) {
      if (!value_param) {
        this.nombre_unidad_var = null;
        this.tipo_unidad_var = null;
        this.sub_unidad_var = null;
        this.stack_unidad_var = [];
        this.$v.$reset();
        this.setIdSelectUnidad(null);
      } else {
        this.FetchTipoUnidadFtn();
        this.FetchUnidadesNotCldFtn();
        this.FetchDetailUnidad();
      }
    },
  },
};
</script>
